<template>
  <div>
    <!-- 头部 -->
    <div
      class="page-header"
      :style="{ borderBottom: showBottom == true ? '1px solid #ccc' : 'none' }"
    >
      <el-row>
        <el-col :span="12">
          <div class="navbar-header">
            <router-link to="/index">
              <img src="../../assets/imgs/logo.png" />
            </router-link>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="bug hidden-sm-and-up">
            <a @click="showMenu"><i class="el-icon-menu menu"></i></a>
          </div>
          <div class="header-right hidden-xs-only">
            <ul>
              <router-link to="/index">
                <li @click="changeActive(1)">
                  <a :class="{ active: isActive == 1 }" href="#">产品</a>
                </li>
              </router-link>

              <router-link to="/case">
                <li @click="changeActive(2)">
                  <a :class="{ active: isActive == 2 }" href="#">案例</a>
                </li>
              </router-link>

              <router-link to="/pricing">
                <li @click="changeActive(3)">
                  <a :class="{ active: isActive == 3 }" href="#">咨询与购买</a>
                </li>
              </router-link>
              <li>
                <div id="user" v-if="isLogin">
                  <router-link to="/home">
                    <span class="into">
                      进入系统
                      <i class="el-icon-right"></i>
                    </span>
                  </router-link>
                  <i class="iconfont icon-user"></i>
                  <a id="userName">{{ userName }}</a>
                </div>
                <div v-else>
                  <el-button @click="goRegister" class="register">
                    注册
                  </el-button>

                  <el-button type="primary" @click="goLogin" class="login">
                    登录
                  </el-button>
                </div>
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="menuBox" v-show="menuBox">
      <ul>
        <router-link to="/index">
          <li @click="changeActive(1)">
            <a :class="{ active: isActive == 1 }" href="#">产品</a>
          </li>
        </router-link>

        <router-link to="/case">
          <li @click="changeActive(2)">
            <a :class="{ active: isActive == 2 }" href="#">案例</a>
          </li>
        </router-link>

        <router-link to="/pricing">
          <li @click="changeActive(3)">
            <a :class="{ active: isActive == 3 }" href="#">咨询与购买</a>
          </li>
        </router-link>
      </ul>
    </div>

    <!-- 背景图 -->
    <div class="bannerImg" id="nav">
      <div class="center">
        <h1 class="slogan">互联网爬虫采集云平台</h1>
        <h4 class="slogan_en">一款基于浏览器的可视化爬虫工具</h4>
        <p class="desc">
          可视化爬虫设计、分布式集群采集、云任务管理、内置爬虫模板
        </p>
        <p class="desc">
          Visualized Design, Distributed Cluster, Cloud Task Management,
          Built-in Templates
        </p>
      </div>
    </div>

    <!-- icon区域 -->
    <div class="icon-nav">
      <el-row class="icon-banner">
        <!-- <div class="icon-banner"> -->
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="grid-content icon-box">
            <div class="iconBox" @click="goSpider('#spider1')">
              <img src="../../assets/imgs/spider-template.png" alt="" />
            </div>
            <span>可视化设计</span>
            <p>专利爬虫框架，在线可视化爬虫设计，轻松编写网络爬虫</p>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="grid-content icon-box">
            <div class="iconBox" @click="goSpider('#spider2')">
              <img src="../../assets/imgs/data-collect.png" alt="" />
            </div>
            <span>分布式集群采集</span>
            <p>分布式服务器集群、高效采集大规模数据</p>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="grid-content icon-box">
            <div class="iconBox" @click="goSpider('#spider3')">
              <img src="../../assets/imgs/search-engine.png" alt="" />
            </div>
            <span>云任务管理</span>
            <p>支持爬虫任务监控和采集结果数据查询</p>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="grid-content icon-box">
            <div class="iconBox" @click="goSpider('#spider4')">
              <img src="../../assets/imgs/text-analysis.png" alt="" />
            </div>
            <span>内置爬虫模板</span>
            <p>丰富的爬虫模块库，可定向采集各主流网络媒体的数据</p>
          </div>
        </el-col>
        <!-- </div> -->
      </el-row>
    </div>

    <!-- 主体内容区域 -->
    <div class="y-main" v-if="isMain">
      <div class="icon-banner">
        <el-row id="spider1">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content">
              <div class="main-img">
                <img
                  src="../../assets/imgs/ringspider-xspider.jpg"
                  width="450px"
                  alt=""
                />
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content main-text">
              <h4 class="content-title">爬虫设计</h4>
              <ul class="content-item">
                <li>专利爬虫配置脚本语言和爬虫引擎XSpider；</li>
                <li>在线可视化爬虫设计工具，无需下载安装爬虫软件；</li>
                <li>
                  基于扩展的XPath和正则语法，可对采集数据进行灵活的处理和格式转换；
                </li>
                <li>提供爬虫开发教程，轻松学习爬虫；</li>
              </ul>
            </div>
          </el-col>
        </el-row>
        <el-row id="spider2">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content main-text">
              <h4 class="content-title">分布式集群采集</h4>
              <ul class="content-item">
                <li>基本XSpider的分布式爬虫框架，支持分布式部署；</li>
                <li>应用分布式服务器集群；</li>
                <li>支持大规模数据采集，日均采集量可达100万；</li>
                <li>支持IP代理池，有效提升反爬能力</li>
              </ul>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content">
              <div class="main-img">
                <img
                  src="../../assets/imgs/ringspider-webextractor.jpg"
                  width="450px"
                  alt=""
                />
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="bgc">
        <el-row class="icon-banner" id="spider3">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content">
              <div class="main-img">
                <img
                  src="../../assets/imgs/ringspider-localsearch.jpg"
                  width="450px"
                  alt=""
                />
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content main-text">
              <h4 class="content-title">云任务管理</h4>
              <ul class="content-item">
                <li>强大的采集任务管理，可设置灵活的定时采集任务；</li>
                <li>支持自定义各类采集爬虫，采集任意网站的数据；</li>
                <li>
                  能进根据数据源配置关键词组合、时间范围、子频道等，实现定向的基于关键词的数据抓取；
                </li>
                <li>数据的抓取的过程能够实时监控和管理。</li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="bgc">
        <el-row class="icon-banner" id="spider4">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content main-text">
              <h4 class="content-title">内置爬虫模板</h4>
              <ul class="content-item">
                <li>提供上百套内置爬虫模板，涵盖主流网站的数据采集模板；</li>
                <li>爬虫模板实时更新和维护；</li>
                <li>支持自定义爬虫模板；</li>
                <li>支持爬虫模板的共享和发布。</li>
              </ul>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content">
              <div class="main-img">
                <img
                  src="../../assets/imgs/ringspider-textanalysis600.png"
                  width="450px"
                  alt=""
                />
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="y-main" v-else>
      <div class="icon-banner">
        <el-row id="spider1">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content">
              <div class="main-img">
                <img
                  src="../../assets/imgs/ringspider-xspider.jpg"
                  width="450px"
                  alt=""
                />
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content main-text">
              <h4 class="content-title">爬虫设计</h4>
              <ul class="content-item">
                <li>专利爬虫配置脚本语言和爬虫引擎XSpider；</li>
                <li>在线可视化爬虫设计工具，无需下载安装爬虫软件；</li>
                <li>
                  基于扩展的XPath和正则语法，可对采集数据进行灵活的处理和格式转换；
                </li>
                <li>提供爬虫开发教程，轻松学习爬虫；</li>
              </ul>
            </div>
          </el-col>
        </el-row>
        <el-row id="spider2">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content">
              <div class="main-img">
                <img
                  src="../../assets/imgs/ringspider-webextractor.jpg"
                  width="450px"
                  alt=""
                />
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content main-text">
              <h4 class="content-title">分布式集群采集</h4>
              <ul class="content-item">
                <li>基本XSpider的分布式爬虫框架，支持分布式部署；</li>
                <li>应用分布式服务器集群；</li>
                <li>支持大规模数据采集，日均采集量可达100万；</li>
                <li>支持IP代理池，有效提升反爬能力</li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-row class="bgc" id="spider3">
        <div class="icon-banner">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content">
              <div class="main-img">
                <img
                  src="../../assets/imgs/ringspider-localsearch.jpg"
                  width="450px"
                  alt=""
                />
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content main-text">
              <h4 class="content-title">云任务管理</h4>
              <ul class="content-item">
                <li>强大的采集任务管理，可设置灵活的定时采集任务；</li>
                <li>支持自定义各类采集爬虫，采集任意网站的数据；</li>
                <li>
                  能进根据数据源配置关键词组合、时间范围、子频道等，实现定向的基于关键词的数据抓取；
                </li>
                <li>数据的抓取的过程能够实时监控和管理。</li>
              </ul>
            </div>
          </el-col>
        </div>
      </el-row>
      <el-row class="bgc" id="spider4">
        <div class="icon-banner">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content">
              <div class="main-img">
                <img
                  src="../../assets/imgs/ringspider-textanalysis600.png"
                  width="450px"
                  alt=""
                />
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content main-text">
              <h4 class="content-title">内置爬虫模板</h4>
              <ul class="content-item">
                <li>提供上百套内置爬虫模板，涵盖主流网站的数据采集模板；</li>
                <li>爬虫模板实时更新和维护；</li>
                <li>支持自定义爬虫模板；</li>
                <li>支持爬虫模板的共享和发布。</li>
              </ul>
            </div>
          </el-col>
        </div>
      </el-row>
    </div>

    <!-- 客户类型 -->
    <div class="clinet">
      <div class="header">
        <h1 class="title">客户类型</h1>
      </div>

      <div class="client-nav icon-banner">
        <el-row>
          <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
            <div class="client-grid">
              <div class="icon-card">
                <img
                  class="img-icon"
                  src="../../assets/imgs/client-enterprise.png"
                />
              </div>
              <h4 class="client-type">企业客户</h4>
              <ul class="client-list">
                <li>企业舆情分析</li>
                <li>竞争情报分析</li>
                <li>招投标监测</li>
                <li>价格指数监测</li>
                <li>产品评价分析</li>
              </ul>
            </div>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
            <div class="client-grid">
              <div class="icon-card">
                <img
                  class="img-icon"
                  src="../../assets/imgs/client-investigate.png"
                />
              </div>
              <h4 class="client-type">市场调研机构</h4>
              <ul class="client-list">
                <li>市场调查与分析</li>
                <li>产品研究</li>
                <li>用户行为研究</li>
                <li>行业研究</li>
                <li>决策咨询</li>
              </ul>
            </div>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
            <div class="client-grid">
              <div class="icon-card">
                <img
                  class="img-icon"
                  src="../../assets/imgs/client-education.png"
                />
              </div>
              <h4 class="client-type">科研教育机构</h4>
              <ul class="client-list padding-left70">
                <li>大数据课程培训</li>
                <li>科研分析</li>
                <li>新闻舆情分析</li>
                <li>网络心态研究</li>
                <li>社会热点研究</li>
              </ul>
            </div>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
            <div class="client-grid">
              <div class="icon-card">
                <img
                  class="img-icon"
                  src="../../assets/imgs/client-government.png"
                />
              </div>
              <h4 class="client-type">政府机关</h4>
              <ul class="client-list">
                <li>社情民意调查</li>
                <li>政府舆情分析</li>
                <li>企业舆情分析</li>
                <li>海外舆情分析</li>
                <li>新闻大数据平台</li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 使用锐研 -->
    <div class="icon-banner use">
      <div class="use-header">
        <h1 class="title">他们正在使用锐研</h1>
      </div>
      <div class="use-body">
        <div class="logo-grids" v-if="isLogo">
          <div class="logo">
            <el-row>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img src="../../assets/imgs/logo1.png" title="南京大学" />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img src="../../assets/imgs/logo2.png" title="复旦大学" />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img src="../../assets/imgs/logo3.png" title="四川大学" />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/logo4.png"
                      title="华南理工大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img src="../../assets/imgs/logo5.png" title="同济大学" />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/logo6.png"
                      title="华东师范大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/logo7.png"
                      title="四川外国语大学"
                    />
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="logo-bottom">
            <el-row>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/logo8.png"
                      title="北京师范大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/logo11.png"
                      title="江苏省电台"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/logo12.png"
                      title="上海市公安局"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img src="../../assets/imgs/logo13.png" title="腾讯网" />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img src="../../assets/imgs/logo9.png" title="河海大学" />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/logo10.png"
                      title="紫金传媒智库"
                    />
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="logo-grids" v-else>
          <div class="logo">
            <el-row>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img src="../../assets/imgs/logo1.png" title="南京大学" />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img src="../../assets/imgs/logo2.png" title="复旦大学" />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img src="../../assets/imgs/logo3.png" title="四川大学" />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/logo4.png"
                      title="华南理工大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img src="../../assets/imgs/logo5.png" title="同济大学" />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/logo6.png"
                      title="华东师范大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/logo7.png"
                      title="四川外国语大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/logo8.png"
                      title="北京师范大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/logo11.png"
                      title="江苏省电台"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/logo12.png"
                      title="上海市公安局"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img src="../../assets/imgs/logo13.png" title="腾讯网" />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img src="../../assets/imgs/logo9.png" title="河海大学" />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/logo10.png"
                      title="紫金传媒智库"
                    />
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="section-bottom">
        <router-link to="/case">
          <a href="#">案例</a>
          <i class="el-icon-arrow-right"></i>
        </router-link>
      </div>
    </div>

    <!-- 底部 -->
    <div class="footer">
      <div class="footer-top">
        <div class="social-icons">
          <a class="icon" href="mailto:marketing@monetware.com" target="_blank">
            <img src="../../assets/imgs/xinXi.png" alt="" />
          </a>
          <a class="icon" href="https://weibo.com/ringdata" target="_blank">
            <img src="../../assets/imgs/weiBo.png" alt="" />
          </a>
          <a
            class="icon mr"
            href="javascript:alert('欢迎关注“锐研中国”微信公众号！')"
            target="_blank"
          >
            <img src="../../assets/imgs/weiXin.png" alt="" />
          </a>
        </div>
      </div>
      <div class="footer-middle">
        <div class="bottom-menu">
          <span>锐研•云采集 互联网大数据研究云平台</span>
        </div>
      </div>
      <div class="footer-bottom">
        <p>
          Copyright &copy;
          <a
            href="http://www.monetware.com"
            target="_blank"
            title="数据研究技术服务商"
          >
            上海萌泰数据科技股份有限公司
          </a>
          2019 &copy;版权所有.
          <a href=" http://beian.miit.gov.cn" target="_blank">
            沪ICP备09099644号
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { isAbsolute } from "path";
import VueCookies from "vue-cookies";
import { httpPostIsAnonymous, httpPost } from "@/api/httpService.js";
import { goLogin, goRegister } from "@/utils/login";

export default {
  data() {
    return {
      isActive: 1, // 切换导航
      isLogin: false, // 用户是否登录
      showBottom: false,
      userName: "",
      menuBox: false,
      isMain: true,
      screenWidth: "",
      isLogo: true,
      goLogin: goLogin,
      goRegister: goRegister,
    };
  },

  created() {
    /*判断用户是否登录*/
    if (VueCookies.isKey("tokenWeb")) {
      let userInfo = VueCookies.get("userInfo");
      this.userName = userInfo.name;
      if (userInfo && !userInfo.ifLoginSpider) {
        httpPost("/user/v1/permission", null).then((res) => {
          if (res && res.code === 0) {
            userInfo.spiderPermission = res.data;
            userInfo.ifLoginSpider = true;
            VueCookies.set("userInfo", JSON.stringify(userInfo));
          }
        });
      }
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听（绑定）滚轮滚动事件
    this.screenWidth = document.body.clientWidth;
    if (this.screenWidth <= 768) {
      this.isMain = false;
      this.isLogo = false;
    } else {
      this.isMain = true;
      this.isLogo = true;
    }
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        if (this.screenWidth <= 768) {
          this.isMain = false;
          this.isLogo = false;
        } else {
          this.isMain = true;
          this.isLogo = true;
        }
      })();
    };
    this.addViewTimes();
  },

  methods: {
    // 切换导航
    changeActive(current) {
      if (current != 1) {
        this.destroyed();
      }
      this.isActive = current;
    },

    // 锚点跳转
    goSpider(id) {
      this.$el.querySelector(id).scrollIntoView(true);
    },

    // 监听滚轮事件
    handleScroll: function () {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll > 0) {
        this.showBottom = true;
      } else {
        this.showBottom = false;
      }
    },

    // 销毁滚轮事件
    destroyed: function () {
      window.removeEventListener("scroll", this.handleScroll); //  离开页面清除（移除）滚轮滚动事件
    },

    // 展开菜单
    showMenu() {
      this.menuBox = !this.menuBox;
    },

    // 增加访问次数
    addViewTimes() {
      let key = "VIEW_SPIDER";
      httpPostIsAnonymous("/show/update/view/" + key, null).then((res) => {
        if (res && res.code === 0) {
          // console.log("")
        }
      });
    },
  },
};
</script>

<style scoped>
.header-right >>> .el-button {
  padding: 8px 13px;
  margin-left: 30px;
  margin-bottom: 3px;
}
</style>
<style lang="scss" scoped>
.mr {
  margin-right: 0 !important;
}
#user {
  line-height: 0;
}
.bug {
  cursor: pointer;
  width: 35px;
  height: 60px;
  float: right;
  padding-right: 20px;
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
  a {
    cursor: pointer;
    width: 35px;
    height: 60px;
    display: inline-block;
    -webkit-tap-highlight-color: transparent;
  }
}
.menu {
  font-size: 28px;
  color: #666;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  line-height: 60px;
}
.menuBox {
  z-index: 1000;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 50px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  li {
    cursor: pointer;
    z-index: 1000;
    a {
      &:hover {
        color: #760478;
      }
      &.active {
        color: #760478;
      }
    }
  }
  .menu-setting {
    border-top: 1px solid #ccc;
    padding-top: 15px;
    .register {
      width: 70px;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      margin-right: 40px;
    }
  }
}
.into {
  margin-left: 30px;
  margin-right: 10px;
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 13px;
  img {
    vertical-align: middle;
    border-style: none;
    padding-bottom: 4px;
    width: 16px;
  }
}
a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  color: #444;
}
button,
button:focus {
  outline: none;
}
ul,
ol {
  list-style: none;
  padding: 0;
}
.icon-banner {
  max-width: 1200px;
  margin: 0 auto;
}
// 头部
.page-header {
  position: fixed;
  left: 0;
  top: 0;
  height: 60px;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  z-index: 10;
  .navbar-header {
    width: 20%;
    height: 60px;
    line-height: 60px;
    float: left;
    padding: 0px 20px 0px;
    img {
      display: inline-block;
      width: 136px;
      height: 40px;
      margin: 9px 0;
    }
  }
  .header-right {
    float: right;
    text-align: right;
    ul {
      width: 100%;
      height: 60px;
      line-height: 60px;
      overflow: hidden;
      margin: 0 !important;
      li {
        margin-right: 20px;
        float: left;
        cursor: pointer;
        a {
          &.active {
            color: #760478;
          }
        }
        .register {
          font-size: 15px;
          font-weight: 500;
          color: #000;
          margin-left: 20px;
        }
        i {
          color: #606266;
        }
        #userImg {
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-right: 5px;
          margin-left: 10px;
        }
        #userName {
          margin-left: 2px;
          display: inline-block;
          padding: 0;
          line-height: 60px;
          font-size: 14px;
          color: #777;
        }
        #userName:hover {
          color: #777;
        }
      }
    }
  }
}

// 背景图
.bannerImg {
  width: 100%;
  color: #fff;
  text-align: center;
  background: url("../../assets/imgs/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 790px;
  .center {
    padding: 200px 0;
    .slogan {
      text-align: center;
      font-size: 40px;
      font-weight: 300;
      color: #fff;
      margin-top: 60px;
      padding: 30px 15px 10px;
    }
    .slogan_en {
      text-align: center;
      color: #fff;
      font-size: 25px;
      font-weight: 300;
      padding: 0 15px 30px;
    }
    .desc {
      font-size: 20px;
      font-weight: 300;
      text-align: center;
      color: #fff;
      max-width: 768px;
      margin: 0 auto;
      margin-bottom: 5px;
      padding: 0 15px;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
    }
  }
}

// icon区域
.icon-nav {
  padding: 80px 0px;
  background-color: #f7f8fd;
  .icon-box {
    text-align: center;
    padding: 10px 15px;
    .iconBox {
      margin: 0 auto 0;
      width: 120px;
      height: 120px;
      line-height: 115px;
      background-color: #ccc;
      border-radius: 50%;
      &:hover {
        background-color: #5f1156;
      }
      img {
        position: relative;
        top: 16px;
      }
    }
    span {
      display: inline-block;
      margin: 25px 0;
      font-size: 18px;
      font-weight: 300;
    }
    p {
      color: #666;
      font-weight: 300;
    }
  }
}

// 主体内容区域
.y-main {
  .bgc {
    background-color: #f7f8fd;
  }
  .main-img {
    padding: 20px 40px;
    img {
      display: block;
      width: 100%;
      margin: 0 auto;
    }
  }
  .main-text {
    padding: 10px 40px;
    .content-title {
      font-size: 25px;
      color: #495766;
      font-weight: 300;
      margin: 20px 0px 30px;
    }
    .content-item {
      list-style: disc;
      color: #666;
      padding-left: 20px;
      li {
        margin: 10px 0px;
      }
    }
  }
}

// 客户类型
.clinet {
  padding: 0px 0px 50px;
  .header {
    .title {
      text-align: center;
      font-size: 28px;
      font-weight: 300;
      color: #495766;
      margin: 20px 0px 30px;
    }
  }
  .client-nav {
    overflow: hidden;
  }
  .client-grid {
    padding-left: 20px;
    width: 100%;
    float: left;
    .icon-card {
      width: 80px;
      margin: 0 auto;
      .img-icon {
        width: 70px;
        height: 70px;
      }
    }
    .client-type {
      font-size: 18px;
      font-weight: 300;
      color: #444;
      text-align: center;
      padding: 15px 0px 0px;
    }
    .client-list {
      list-style: disc;
      color: #666;
      padding-left: 0px;
      li {
        margin: 10px 0px;
        text-align: center;
        color: #666;
        font-weight: 300;
      }
    }
  }
}

// 使用锐研
.use {
  .use-header {
    .title {
      text-align: center;
      font-size: 30px;
      color: #666;
      font-weight: 300;
      margin-bottom: 20px;
    }
  }
  .use-body {
    overflow: hidden;
    .logo-grids {
      margin: 0 auto;
      height: auto;
      width: 100%;
      text-align: center;
      .logo-card {
        width: 100%;
      }
    }
    img {
      width: 100%;
    }
  }

  .logo-bottom {
    img {
      width: 100%;
    }
  }
  .section-bottom {
    overflow: hidden;
    margin: 40px 0;
    text-align: center;
    a {
      font-size: 16px;
      color: #5f1156;
      font-weight: 500;
    }
    i {
      font-weight: bold;
    }
  }
}

// 底部
.footer {
  padding: 55px 10px;
  text-align: center;
  background: #eee;
  .social-icons img {
    display: inline-block;
    width: 25px;
    height: 25px;
  }
  .social-icons a {
    width: 45px;
    height: 45px;
    line-height: 45px;
    display: inline-block;
    background: #bdbdbd;
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 45px;
    margin-right: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    &:hover {
      opacity: 0.8;
      filter: alpha(opacity=80);
      -webkit-filter: alpha(opacity=80);
      -moz-opacity: 0.8;
      -khtml-opacity: 0.8;
    }
  }
  .footer-middle {
    color: #a0a0a0;
    padding: 10px 0px;
    font-size: 12px;
  }
  .footer-bottom {
    font-size: 12px;
    p {
      color: #a0a0a0;
    }
    a {
      color: #337ab7;
    }
  }
}
</style>
